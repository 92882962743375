import './App.css';
import Routing from './routing/Routing';
function App() {
  
  return (
    <div >
      
      {/* <Home/> */}
      <Routing />
    </div>
  );
}

export default App;